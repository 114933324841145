import { getDashboardOnboardingSteps } from "src/components/Dashboard/DashboardOnboarding/dashboardOnboardingUtils"
import { getDashboardTodos } from "src/components/Dashboard/DashboardTodos/dashboardTodosUtils"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { useFetchAvailableHomeTokens } from "src/data/homes/queries/homeTokenQueries"
import { useFetchIntegrations } from "src/data/integrations/queries/integrationQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomesCount } from "src/data/organizations/queries/homeQueries"
import { useFetchMemberInvites } from "src/data/organizations/queries/inviteQueries"
import { useFetchMemberCount } from "src/data/organizations/queries/memberQueries"
import { InvitationState } from "src/data/organizations/types/organizationTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useFetchUserDecisions } from "src/data/userDecisions/userDecisionsQueries"

export function useGetDashboardData() {
  const { org } = useOrganization()
  const { user_id: userId } = useGetUser()

  const userOrgAccessLevel = getAccessLogic({
    role: org.user_role,
  })

  const subscriptionIsCancelled =
    org.current_subscription_status === "cancelled"

  const fetchHomesCount = useFetchHomesCount({ orgId: org.id })
  const fetchDevices = useFetchDevices({ orgId: org.id })
  const fetchUserDecisions = useFetchUserDecisions({
    userId,
    filters: {
      decisions:
        "onboarding_integration_cta_declined,onboarding_team_member_dismissed",
    },
    options: { enabled: !subscriptionIsCancelled },
  })
  const fetchConfiguredIntegrations = useFetchIntegrations(
    {
      orgId: org.id,
      filters: {
        configured: true,
        includeExternalIntegrations: true,
      },
    },
    { enabled: !subscriptionIsCancelled }
  )

  const fetchAvailableHomeTokens = useFetchAvailableHomeTokens({
    orgId: org.id,
  })

  const fetchMembersCount = useFetchMemberCount({
    orgId: org.id,
  })

  const fetchMemberInvites = useFetchMemberInvites({
    orgId: org.id,
    filter: {
      invitation_state: InvitationState.PENDING,
    },
  })

  const homesCount = fetchHomesCount.data || 0
  const devicesCount = fetchDevices.data?.paging.total_count || 0
  const configuredIntegrationsCount =
    fetchConfiguredIntegrations.data?.integrations?.length || 0
  const availableHomeTokensCount =
    fetchAvailableHomeTokens.data?.paging.total_count || 0

  const userDecisions = fetchUserDecisions.data?.decisions
  const membersCount = fetchMembersCount.data || 0
  const pendingInvitesCount = fetchMemberInvites.data?.paging?.total_count || 0

  const loading =
    fetchHomesCount.isInitialLoading ||
    fetchDevices.isInitialLoading ||
    fetchConfiguredIntegrations.isInitialLoading ||
    fetchUserDecisions.isInitialLoading ||
    fetchAvailableHomeTokens.isInitialLoading ||
    fetchMembersCount.isInitialLoading

  const hasDeclinedIntegrationCta = !!userDecisions?.find(
    (i) => i.decision === "onboarding_integration_cta_declined"
  )

  const hasDeclinedAddMemberTodo = !!userDecisions?.find(
    (i) => i.decision === "onboarding_team_member_dismissed"
  )

  const onboardingSteps = getDashboardOnboardingSteps({
    homesCount,
    devicesCount,
    configuredIntegrationsCount,
    hasDeclinedIntegrationCta,
  })

  const currentOnboardingStep =
    onboardingSteps.find((i) => !i.completed)?.id || "CREATE_HOME_STEP"

  const showOnboarding =
    !subscriptionIsCancelled && !!onboardingSteps.find((i) => !i.completed)

  const allTodos = getDashboardTodos({
    availableHomeTokensCount,
    userOrgAccessLevel,
    homesCount,
    membersCount,
    hasDeclinedAddMemberTodo,
    showOnboarding,
    pendingInvitesCount,
    billingModel: org.billing_model,
  })

  const showTodos = Object.values(allTodos).some(Boolean)

  return {
    loading,
    devicesCount,
    homesCount,
    availableHomeTokensCount,
    membersCount,
    pendingInvitesCount,
    todos: {
      allTodos,
      showTodos,
    },
    onboarding: {
      onboardingSteps,
      currentOnboardingStep,
      showOnboarding,
    },
  }
}
